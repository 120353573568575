<script>

import UserService from "@/services/UserService";

export default {
  data(){
    return {
      show_error: false,
      form: {
        code: ''
      }
    }
  },
  methods: {
    submit() {
      this.show_error = false;
      const userId = window.localStorage.getItem('verification-phone-user-id');
      UserService.verifyPhone(this.form.code, userId)
        .then(() => {
          this.$router.push({name: 'registration-success'})
        })
          .catch(() => {
            this.show_error = true;
            console.log('error')
          })
    }
  }
}
</script>

<template>
    <div class="auth-page-wrapper pt-5">
        <!-- auth page bg -->
        <!-- auth page bg -->
        <div class="auth-one-bg-position auth-one-bg" id="auth-particles">
            <div class="bg-overlay"></div>

            <div class="shape">

                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 1440 120">
                    <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z"></path>
                </svg>
            </div>
        </div>

        <!-- auth page content -->
        <div class="auth-page-content">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="text-center mt-sm-5 mb-4 text-white-50">
                            <div>
                                <router-link to="/" class="d-inline-block auth-logo">
                                    <img src="@/assets/images/logo-j2p.png" alt="" height="50">
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- end row -->
              <div class="row justify-content-center">
                    <div class="col-md-8 col-lg-6 col-xl-5">
                        <div class="card mt-4">

                            <div class="card-body p-4">
                              <div class="avatar-lg mx-auto mt-2">
                                <div class="avatar-title bg-light text-success display-3 rounded-circle"><i class="ri-checkbox-circle-fill"></i></div>
                              </div>
                              <div class="text-center mt-2">
                                  <h5 class="text-primary">{{ $t("t-verification-step") }}</h5>
                                  <p class="text-muted">{{$t("t-verification-info")}}</p>
                              </div>
                              <div class="p-2 mt-4">
                                <form class="needs-validation" v-on:submit.prevent="submit">

                                  <div class="mb-3">
                                    <label for="verification-code" class="form-label">{{ $t("t-phone-verification-code") }} <span
                                        class="text-danger">*</span></label>
                                    <input type="text" class="form-control" id="verification-code"
                                           v-model="form.code"
                                           :placeholder='$t("t-please-enter-code")' required>
                                    <div class="invalid-feedback" :style="{display: (this.show_error ? 'block' : 'none')}">
                                      {{ $t("t-incorrect-verification-code") }}
                                    </div>
                                  </div>
                                </form>
                              </div>
                              <div class="mt-4">
                                <button type="submit" class="btn btn-success w-100" @click="submit">{{ $t("t-verify") }}</button>
                              </div>
                            </div>
                            <!-- end card body -->
                        </div>
                        <!-- end card -->


                    </div>
                </div>
                <!-- end row -->
            </div>
            <!-- end container -->
        </div>
        <!-- end auth page content -->

        <!-- footer -->
        <footer class="footer">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="text-center">
                          <p class="mb-0 text-muted">&copy; {{new Date().getFullYear()}} Damask.</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        <!-- end Footer -->
    </div>
    <!-- end auth-page-wrapper -->
</template>